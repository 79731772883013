'use client'
import { getCompany } from "@/actions/auth";
import { update } from "@/actions/crm/contact";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Form } from "@/components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import _ from "lodash";
import { Loader2 } from "lucide-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { AditionalDataContact } from "../ContactDrawer/AdditionalDataForm";


interface ModalAlertOnSaleProps {
    open: string[] | boolean,
    contact: any,
    onClose: (archived:boolean) => void
}


export const ModalAlertOnSale = ({ open, onClose, contact }: ModalAlertOnSaleProps) => {

    const [fields, setFields] = useState<string[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    const requiredOnSaleFields = useMemo(() => {
        const additionalDataFields = _.get(getCompany(), 'additionalDataFields', []);
        return additionalDataFields.filter(field => field.requiredOnSale);
    }, [contact]);

    const formSchema = z.object({
        additionalData: z.unknown()
    });
    

    useEffect(() => {
        open ? setFields(open) : setFields([])
    }, [open])

    const contextForm = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            additionalData: {},
        }
    });

    const handleSubmit = async (values: z.infer<typeof formSchema>) => {
        const contactID = contact._id as string
        const contactUpdate = { ...contact, additionalData: values.additionalData }
        setLoading(true)
        try {
            await update(contactID, contactUpdate)
                .then((response) => {
                    setLoading(false)
                    onClose(true)
                }).catch((err) => {
                    setLoading(false)
                    onClose(true)
                })
        } catch (err) {
            setLoading(false)
        }
    }

    const additionalData = contextForm.watch('additionalData');

    const getAdditionalData = (nameField: string) => {
        return additionalData && additionalData[nameField] ? additionalData[nameField] : ""
    };

    const handleAdditioanlData = useCallback((name: string, value: string) => {
        if (!additionalData) contextForm.setValue('additionalData', { [name]: value })
        else contextForm.setValue('additionalData', { ...additionalData, [name]: value })
    }, [additionalData]
    );


    const dataField = getCompany().additionalDataFields.filter((f) => f.active && f.requiredOnSale)

    useEffect(() => {
        if(contact?.additionalData){
            contextForm.setValue('additionalData', contact?.additionalData)
        }
    }, [contact])

    return (
        <Dialog open={!!open} onOpenChange={() => onClose(false)} >
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Campos requeridos en venta</DialogTitle>
                    <DialogDescription className="flex">
                        <p>¡Completa los siguientes campos requeridos a la hora de realizar una venta!</p>
                    </DialogDescription>
                </DialogHeader>
                <Form  {...contextForm}>
                    <form onSubmit={contextForm.handleSubmit(handleSubmit)} className="space-y-2">
                        <div className="pb-4">
                            <AditionalDataContact
                                id={contact?._id}
                                getAdditionalData={getAdditionalData}
                                handleAdditioanlData={handleAdditioanlData}
                                requiredOnSale={true}
                                additionalDataFields={dataField} />
                        </div>

                        <DialogFooter className="flex flex-row items-center justify-center">
                            <Button type="button" variant={'outline'} onClick={() => onClose(false)}>Cancelar</Button>
                            {loading ? <Loader2 className="h-4 w-4 animate-spin text-primary" /> : <Button variant={'default'} type="submit">Guardar y archivar</Button>}
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog >
    )
}
