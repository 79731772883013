import { useQuery } from "@tanstack/react-query"
import notificationService, { IPropsGet } from '@/actions/notifications';
import { INotification } from "@/components/Header/NavBar";

export type TOrderBy = 'asc' | 'desc'

interface Props extends IPropsGet {
    enabled?: boolean,
    popup?: boolean
    orderBy: TOrderBy,
    queryKey?: Array<string>
}

interface IReturnHook {
    notifications: Array<INotification>,
    countDocuments: number,
    refetch: () => void,

    isError: boolean,
    countAllNotifications: number,
    isLoading: boolean
}

export default function useGetNotifications({
    all,
    page,
    limit,
    orderBy = 'desc',
    popup = false,
    type,
    enabled = true,
    queryKey = ['all-notifications-user'] }: Props): IReturnHook {

    const { data: {
        notifications,
        countDocuments,
        countAllNotifications } = {},
        refetch,
        isLoading,
        isError } = useQuery({
            queryKey,
            queryFn: () => notificationService.getAllByUser({ all, page, limit, type, popup, orderBy }),
            enabled
        })

    return {
        notifications: notifications || [],
        countDocuments: countDocuments || 0,
        countAllNotifications: countAllNotifications || 0,
        refetch,
        isLoading,
        isError
    }
}