import { INotificationAdmin } from "@/pages/grupos/platformNotifications"
import "moment/locale/es"
import { CardNotification } from "./CardNotification"
import { INotification } from "./NavBar"

export default function ListOfNotifications({ notifications, refetchNotififcations }) {
    const notificationsCount = notifications.length

    return notificationsCount > 0 ? (
        <div
            style={{
                marginBottom: `${notificationsCount > 6 ? 6 * 10 : notificationsCount * 9}px`,
            }}
        >
            <div className="grid" style={{ gridTemplateColumns: "1fr" }}>
                {notifications
                    .slice(-6)
                    .map((notification: INotification, inx) => {
                        const isGreaterThanSix =
                            notificationsCount > 6 ? 6 : notificationsCount
                        return (
                            <CardNotification
                                refetch={() => refetchNotififcations()}
                                key={notification._id}
                                groupCard={true}
                                notification={notification as INotificationAdmin}
                                style={{
                                    top: `${inx * 9}px`,
                                    transform: `scale(${1 - (isGreaterThanSix - 1 - inx) * 0.04
                                        })`,
                                    opacity: 1 - (isGreaterThanSix - 1 - inx) * 0.1,
                                }}
                            />
                        )
                    })}
            </div>
        </div>
    ) : <></>
}
