import { setReply } from "@/redux/slices/crm"
import { Reply, X } from "lucide-react"
import { useDispatch, useSelector } from 'react-redux';

interface ReplyProps {
    interaction: {
        id: string,
        message: string
    }
    isReplied?: boolean
    onClick?: () => void
}

export const ReplyCard = ({ interaction, isReplied }: ReplyProps) => {
    const scrollIntoMessage = (id: string) => {
        const message = document.getElementById(id)
        if (message) message.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }

    const dispatch = useDispatch()
    return (
        <div className={`p-2 flex justify-between border-l-4 rounded border-l-blue-600 bg-gray-100 ${isReplied ? 'm-0 cursor-pointer' : 'm-2'}`} onClick={() => scrollIntoMessage(interaction.id)} >
            <div className="flex gap-4 items-center">
                <Reply size={20} className="text-blue-600" />
                <p className="text-[14px] font-light">{interaction.message}</p>
            </div>
            {!isReplied && <X size={14} className="cursor-pointer" onClick={() => dispatch(setReply(null))} />}
        </div>
    )
}