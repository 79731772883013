import AudioInteraction from "./audio-interaction";
import ExternalAdReply from './ExternalAdReply';
import FileInteractionCard from "./file-interaccion-card";
import ImageInteractionChannel from "./image-interaction-channel";
import InteractionVideo from "./interaction-video";

export default function InteractionChannelContent({
  children,
  mediaType,
  media,
  description,
  channel,
  origin,
  date,
  listened,
  externalAdReply,
  status,
  fileName
}: any) {
  const props = { media, description, origin, date, status, channel, fileName }

  if (externalAdReply) return <ExternalAdReply externalAdReply={externalAdReply} >
    {children}
  </ExternalAdReply>

  if (mediaType === 'image') return <ImageInteractionChannel {...props} >
    {children}
  </ImageInteractionChannel  >

  if (mediaType === 'video') return <InteractionVideo {...props} >
    {children}
  </InteractionVideo>

  if (mediaType === 'file') return <FileInteractionCard {...props} fileName={fileName || description}>
    {children}
  </FileInteractionCard  >

  if (mediaType === 'audio') return <AudioInteraction {...props} listened={listened}>
    {children}
  </AudioInteraction  >

  return <div>
    {children}
  </div>
}
