import { namesOfOptions } from "@/constants/modalReminder"
import { getStatusTagByCode } from "@/helpers/tagColorToPalette"
import { MessageCircleMore } from "@/icons"
import { MeetingIcon } from "@/icons/AppsToreIcons"
import { cn } from "@/lib/utils"
import { Alarm, CompareArrows, Description, Email, Facebook, Inbox, Instagram, ListAlt, MeetingRoom, Phone, WhatsApp } from "@mui/icons-material"
import _ from "lodash"
import { ArchiveRestore, CalendarClock, MessagesSquare, ReceiptText, TagIcon } from 'lucide-react'
import moment from "moment"
import React, { ReactNode } from "react"
import sanitizeHtml from 'sanitize-html'
import { getCompany } from "../../actions/auth"
import { Archiving, ChannelMessage, ContactTakenBase, EmailBase, ExpiredMeeting, MeetingBase, NoteBase, PhoneCall, Reminder, ReminderMeetingBase, SMS, SaleInteraction, SaleUpdate, ScheduledMessage, SubConversationClosedBase, SubConversationCreatedBase, TagBase, Transfer, UnOfficialWhatsappBase, Visit } from "../../interfaces/crm/contact"
import { formatMessage, sanitizeTextOptions } from "../StyledLinkifyer"

moment.locale("es");

interface TextInteractionInterface {
  interaction: (
    Archiving
    | PhoneCall
    | Transfer
    | ReminderMeetingBase
    | ExpiredMeeting
    | NoteBase
    | Reminder
    | Visit
    | SaleUpdate
    | UnOfficialWhatsappBase
    | ContactTakenBase
    | ChannelMessage
    | SMS
    | TagBase
    | EmailBase
    | MeetingBase
    | ScheduledMessage
    | SubConversationClosedBase
    | SubConversationCreatedBase) & { message: string }
  | SaleInteraction
}

export const maxText = (text: string, length: number) => {
  if (text.length > length + 3) return text.substring(0, length) + "..."
  return text;
};

const saleInteractionText: Record<SaleInteraction['content']['type'], string> = {
  'cancellation': 'Venta cancelada',
  'created': 'Venta creada',
  'state-change': 'Cambio de estado de venta',
  'updated': 'Venta actualizada'
};

const getIconChannelMedium = (medium: 'ig' | 'wpp' | 'fb') => {
  const map = {
    'ig': <Instagram sx={{ fontSize: "14px" }} />,
    'fb': <Facebook sx={{ fontSize: "14px" }} />,
    'wpp': <WhatsApp sx={{ fontSize: "14px" }} />,
  };

  return map[medium];
};

const TextInteraction = ({ interaction }: TextInteractionInterface) => {
  let company = getCompany();


  switch (interaction.type) {
    case "note":
      const processedMessage = formatMessage(interaction.content.body || '');
      const sanitizedMessage = sanitizeHtml(processedMessage, sanitizeTextOptions);

      return (
        <React.Fragment>
          {interaction.content.channel == "whatsapp" ? (
            <WhatsApp sx={{ fontSize: "14px" }} />
          ) : (
            <Description sx={{ fontSize: "14px" }} />
          )}{" "}
          <div dangerouslySetInnerHTML={{ __html: sanitizedMessage }} className="max-w-[100%] truncate overflow-hidden" />
        </React.Fragment>
      );
    case "smsMessage":
      const { message }: any = interaction.content || {}
      return (
        <>
          <MessageCircleMore className="w-[15px] h-[15px]" />
          {message}
        </>
      );
    case "unofficial-whatsapp":
      const processedWppMessage = formatMessage(interaction.content.message || '');
      const sanitizedWppMessage = sanitizeHtml(processedWppMessage, sanitizeTextOptions);

      return (
        <>
          <WhatsApp sx={{ fontSize: "14px" }} />{" "}
          {(!interaction.content.message || interaction.content.message.length == 0) ?
            (
              interaction.content.mediaType == "video" ? "Video" : interaction.content.mediaType == "audio" ? "Audio" : interaction.content.mediaType == "file" ? "Archivo" : interaction.content.mediaType == "image" ? "Imagen" : "Mensaje"
            )
            : <div dangerouslySetInnerHTML={{ __html: sanitizedWppMessage }} className="max-w-[100%] truncate overflow-hidden" />}
        </>
      );
    case "channelMessage":
      const processedChannelMessage = formatMessage(interaction?.content?.message?.text || '');
      const sanitizedChannelMessage = sanitizeHtml(processedChannelMessage, sanitizeTextOptions);

      return (
        <>
          {getIconChannelMedium(interaction.content.medium)}
          {" "}
          {(interaction.content?.message?.type && interaction.content.message.type !== 'text') ?
            (
              interaction.content.message.type == "video" ? "Video" : interaction.content.message.type == "audio" ? "Audio" : interaction.content.message.type == "document" ? "Documento" : interaction.content.message.type == "image" ? "Imagen" : "Mensaje"
            )
            : <div dangerouslySetInnerHTML={{ __html: sanitizedChannelMessage }} className="max-w-[100%] truncate overflow-hidden" />}
        </>
      );

    case "phoneCall":
      return (
        <>
          <Phone sx={{ fontSize: "14px" }} />{" "}
          {interaction.content.state == "done"
            ? "llamada contestada"
            : interaction.content.state == "cancel"
              ? "llamada rechazada"
              : interaction.content.state == "voicemail"
                ? "correo de voz"
                : "en llamada"}
        </>
      );

    case "reminder": return getReminderText(interaction)
    case "transfer":
      return <>
        <CompareArrows sx={{ fontSize: "13px" }} />
        transferido
      </>
    case "contactTaken":
      return <>
        <Inbox sx={{ fontSize: '13px' }} />
        tomado
      </>

    case "visit":
      let visitName = "";
      company.visitResults.map((a) => {
        if (a.code == interaction.content.state) {
          visitName = a.name;
        }
      });
      return (
        <>
          <MeetingRoom sx={{ fontSize: "13px" }} />
          {visitName}
        </>
      );

    case "archiving":
      let archive = "";
      _.get(company, "archivingReasons", []).map((a) => {
        if (a.code == interaction.content.to) {
          archive = a.name;
        }
      });
      return <>
        <ArchiveRestore size={13} />
        {archive != "" ? archive : (interaction.content.to && interaction.content.to.length > 0) ? interaction.content.to : "desarchivado"}
      </>

    case "saleupdate":
      let text: undefined | string = "";
      switch (interaction.content.type) {
        case "comment":
          text = interaction.content.comment
          break;
        case "created":
          text = "creado";
          break;
        case "state-change":
          text = interaction.content.state
          break;
        case "updated":
          text = "modificado";
          break;
      }

      return (
        <>
          <ListAlt sx={{ fontSize: "14px" }} />
          {text}
        </>
      );
    case "email":
      return (
        <>
          <Email sx={{ fontSize: '14px' }} /> {interaction?.content?.subject}
        </>
      )
    case "tagChange":
    case "tag":
      const { to } = interaction?.content || {}
      const realTagName = getStatusTagByCode(to)?.name
      return (
        <>
          <TagIcon size={14} /> {(!to || to == '') ? 'Etiqueta eliminada' : (realTagName ?? to)}
        </>
      )
    case "meeting":
      return (
        <>
          <MeetingIcon height={'14px'} width={'14px'} fill="#737373" /> Reunión
        </>
      )
    case "subconversation-created":
      return (
        <>
          <MessagesSquare size={14} />
          Sub-Conversación creada
        </>
      )
    case "subconversation-closed":
      return (
        <>
          <MessagesSquare size={14} />
          Sub-Conversación cerrada
        </>
      )
    case 'expired-meeting':
      return <>
        <CalendarClock size={14} />
        Reunión expirada
      </>
    case 'meeting-reminder':
      return <>
        <CalendarClock size={14} />
        Recordatorio de reunión
      </>
    case 'scheduled-message':
      return <>
        <CalendarClock size={14} />
        Mensaje programado
      </>
    case 'sale': {
      const type = (interaction as SaleInteraction)?.content?.type || 'created';
      const interactionText = saleInteractionText[type] || saleInteractionText['created'];

      return <>
        <ReceiptText size={14} />
        {interactionText}
      </>
    }
    default:
      return <>Interaccion desconocida</>;
  }
};

const getReminderText = (interaction: Reminder) => {
  const { content, via } = interaction || {};
  const { reminderDate, actionType } = content || {};
  const { icon, name } = namesOfOptions[actionType] || {};

  const iconUse = actionType
    ? <span className="[&>svg]:w-[14px] [&>svg]:h-[14px]">{icon}</span>
    : <Alarm sx={{ fontSize: "14px" }} />;

  const getMessage = () => {
    if (reminderDate) return '';
    if (via === 'server') return name ? `${name} vencido` : 'Recordatorio vencido';
    return name ? `${name} cancelado` : 'Recordatorio cancelado';
  };

  const message = getMessage();

  return (
    <div className="w-full overflow-hidden items-center flex gap-1 relative">
      {iconUse}
      <span className="relative max-w-[100%] truncate">
        {message || moment(interaction.content.reminderDate).format("LLL")}
      </span>
    </div>
  );
};


export function TextInteractionElement({ interaction }: TextInteractionInterface) {
  return <InteractionStyles>
    <TextInteraction interaction={interaction} />
  </InteractionStyles>
}

interface IPropsInteractionStyles {
  children: ReactNode
  className?: string
  styles?: {}
}

export function InteractionStyles({ children, className }: IPropsInteractionStyles) {
  return <p
    className={cn(`text-[12px] text-neutral-500 truncate whitespace-nowrap overflow-hidden [&>svg]:float-left [&>svg]:mt-[3px] [&>svg]:mr-1 max-w-[100%] font-medium relative gap-1`, className)}
  >
    {children}
  </p>
}
