import { changeToGhostAdmin, isAuth, isGhostAdmin } from "@/actions/auth"
import { Button } from "@/components/ui/button"
import { ROLES, isTheRolEnabled } from "@/helpers/featureFlagHelpers"
import GroupIcon from '@mui/icons-material/Group'
import { DoorOpen, Menu, MessageSquare } from "lucide-react"
import { useRouter } from "next/router"
import useGetLink from "../../hooks/useGetListOfLinks"
import { Backoffice } from "../crm/Bubble/Icons"
import { Performance } from "../crm/Bubble/Icons/Sidebar"
import { Accordion } from "../ui/accordion"
import ListOfNotifications from "./ListOfNotifications"
import NavLink from "./NavLink"
import NavSubMenuItem from "./NavSubMenuItem"
import BtnCreateContact from "./btn-create-contact"
import useMenu from "./libs/hooks/useMenu"
import { CODES_SUBMENUES } from "./libs/interfaces-enums"
import ChangeAccountBtn from "./ChangeAccountBtn"
import _ from "lodash"
import "moment/locale/es"
import ChangeLinkedAccounts from "./ChangeAccount/change-linked-accounts"

export interface INotification {
  _id: string
  createdAt: string | Date
  updatedAt: string | Date
  popup: boolean
  title: string
  description: string
  image?: string
  redirectURL: string
  type: "video" | "article"
}

export default function NavBar({
  user,
  group,
  handleOpenMenu,
  slug,
  notifications,
  refetchNotififcations,
}) {
  const router = useRouter()
  const { handleChangeValue, isCollapsed: isCollapsedMenu, valueOfSubMenues } = useMenu()

  const {
    itemsBackoffice,
    itemsHome,
    inboxItems,
    restOptionHeader,
    itemsContacts
  } = useGetLink({
    group,
    isCollapsedMenu
  })

  return (
    <>
      <div className="flex flex-col shrink-0">
        <div className={`${isCollapsedMenu ? 'm-auto w-full mb-2 px-[13px] border-b  border-slate-200' : 'px-3 mb-1'} py-2 `}>
          <BtnCreateContact isCollapsedMenu={isCollapsedMenu} />
        </div>
        <nav className="flex-grow pb-5 flex flex-col justify-between">
          <Accordion
            onValueChange={handleChangeValue}
            value={valueOfSubMenues}
            type="multiple"
          >
            <ul className={`mt-[2px] mb-2 ${isCollapsedMenu ? "px-[13px] " : "px-3 mt-2"} flex justify-between flex-col  gap-1 flex-grow`}>
              <NavSubMenuItem
                href={'/'}
                slug={slug}
                isExpandable={true}
                iconLabel={<Performance />}
                contentSlugs={itemsHome.filter(item => item?.slug).map(item => item?.slug) as string[]}
                value={CODES_SUBMENUES.CHARTS}
                toolTipContent={'Rendimiento'}
                isFirstSelect={slug === 'statistics'}
                icon={<Performance className="w-[17px] h-[17px]" />}
                open={valueOfSubMenues}
                isCollapsedMenu={isCollapsedMenu}
                label={"Rendimiento"}
              >
                {
                  itemsHome
                    .filter((option) => option.condition)
                    .map(({ href, icon, label, ...rest }: any) => (
                      <NavLink
                        isCollapsedMenu={isCollapsedMenu}
                        currentSlug={slug}
                        slug={rest?.slug}
                        key={label}
                        className={`px-[20px]  ${isCollapsedMenu ? "lg:px-0" : "ml-[8px]"}`}
                        onAction={rest?.onAction}
                        href={href}
                        icon={icon}
                        label={label}
                        notification={0}
                      />
                    ))
                }
              </NavSubMenuItem>
              {
                user?.productType == "ventia_freelancer" ||
                  _.get(isAuth(), "jailUrl", []).length > 0 ?
                  inboxItems.filter((op: any) => op.condition)
                    .map((op: any) => (
                      <NavLink
                        {...op}
                        key={op.href}
                        isCollapsedMenu={isCollapsedMenu}
                        notification={op?.notification || 0}
                        currentSlug={slug}
                        className={`px-2 ${isCollapsedMenu ? "lg:px-0" : ""}`}
                      />
                    ))
                  : <NavLink
                    isCollapsedMenu={isCollapsedMenu}
                    href={"https://web.sirena.app/agent/shared/user/"}
                    icon={<MessageSquare size={20} />}
                    label={"Conversaciones"}
                    notification={0}
                    target="_blank"
                  />
              }
              <NavSubMenuItem
                href={'/contacts'}
                contentSlugs={itemsContacts.filter(item => item.slug).map(item => item?.slug) as string[]}
                value={CODES_SUBMENUES.CONTACTS}
                slug={slug}
                isFirstSelect={slug === 'contacts'}
                icon={<GroupIcon style={{ fontSize: '20px' }} />}
                open={valueOfSubMenues}
                isCollapsedMenu={isCollapsedMenu}
                label={"Contactos"}
              >
                {
                  itemsContacts
                    .filter(op => op.condition)
                    .map((op) => <NavLink
                      {...op}
                      key={op.href}
                      isCollapsedMenu={isCollapsedMenu}
                      currentSlug={slug}
                      className={`px-[20px]  ${isCollapsedMenu ? "lg:px-0" : "ml-[8px]"}`}
                    />
                    )
                }
              </NavSubMenuItem>
              {
                isTheRolEnabled(ROLES.COMERCIAL) && <NavSubMenuItem
                  slug={slug}
                  href={'/comercial/sale'}
                  contentSlugs={itemsBackoffice.filter(item => item.slug).map(item => item?.slug) as string[]}
                  value={CODES_SUBMENUES.BACKOFFICE}
                  isCollapsedMenu={isCollapsedMenu}
                  isFirstSelect={router.asPath === '/comercial/sale'}
                  icon={<Backoffice className="w-[17px] h-[17px]" />}
                  iconLabel={<Backoffice className="w-[16px] h-[16px]" />}
                  open={valueOfSubMenues}
                  label={"Backoffice"}
                  notification={0}
                >
                  {
                    itemsBackoffice
                      .filter(op => op.condition)
                      .map((op) => <NavLink
                        {...op}
                        currentSlug={slug}
                        key={op.href}
                        isCollapsedMenu={isCollapsedMenu}
                        className={`px-[20px]  ${isCollapsedMenu ? "lg:px-0" : "ml-[8px]"}`}
                      />
                      )
                  }
                </NavSubMenuItem>
              }
              <div className={`w-full ${isCollapsedMenu ? 'lg:hidden' : 'py-0.5 lg:block'} block`}>
              </div>
              {
                restOptionHeader
                  .filter(op => op.condition)
                  .map((op) =>
                    <NavLink
                      {...op}
                      group={group}
                      isCollapsedMenu={isCollapsedMenu}
                      key={op.label}
                      currentSlug={slug}
                    />
                  )
              }

              <li>
                <ChangeLinkedAccounts isCollapsedMenu={isCollapsedMenu} />
              </li>

              {(isTheRolEnabled(ROLES.ADMIN) || isGhostAdmin()) && (
                <li className="w-full">
                  <ChangeAccountBtn
                    isCollapsedMenu={isCollapsedMenu} />
                </li>
              )}

              <li className={`hidden ${isCollapsedMenu ? 'lg:flex items-center justify-center' : ''}`}>
                <Button
                  onClick={() => handleOpenMenu()}
                  className="px-0 w-[36px] h-[33px] m-auto relative hover:text-slate-500 rounded-[5px] text-slate-500 hover:bg-transparent focus:bg-primary focus:text-white" variant={'ghost'}>
                  <Menu size={23} />
                </Button>
              </li>
              <li className="mt-2">
                {
                  user && isGhostAdmin() && (
                    <NavLink
                      isCollapsedMenu={isCollapsedMenu}
                      onAction={() => changeToGhostAdmin(() => router.reload())}
                      icon={<DoorOpen size={17} />}
                      href="javascript:void(0)"
                      label={"Volver a administrador"}
                      notification={0}
                    />
                  )
                }
              </li>
            </ul>
          </Accordion>
          <div className={`block px-4 ${isCollapsedMenu ? 'lg:hidden ' : ''}`}>
            <ListOfNotifications
              notifications={notifications}
              refetchNotififcations={refetchNotififcations}
            />
          </div>
        </nav>
      </div>
    </>
  )
}
