import axios from 'axios'
const API = process.env.API
const API_NEXT = process.env.API_NEXT
import { getCookie, getLocalStorage } from '../auth'
import { fetcher } from '@/helpers/fetcher'


export const upload = (group, form) => {

    return axios.post(`${API}/sales/${group}/upload`, form,
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + getCookie('token')
            }
        })
}

export const uploadUser = (form) => {

    return axios.post(`${API}/sales/upload`, form,
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + getCookie('token')
            }
        })
}

export const updateUser = (id, user) => {

    return axios.patch(`${API}/receipt/${id}/changeuser`, { user },
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + getCookie('token')
            }
        })
}


export const updateSaleDate = (id, date) => {

    return axios.patch(`${API}/receipt/${id}/changesaledate`, { date },
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + getCookie('token')
            }
        })
}

export const updatePreUser = (id, user) => {

    return axios.patch(`${API}/presale/${id}/changeuser`, { user },
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + getCookie('token')
            }
        })
}

export const addrecepit = (sale) => {

    return axios.post(`${API_NEXT}/receipt`, { sale },
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + getCookie('token')
            }
        })
}

export const addrecepitadmin = (sale) => {

    return axios.post(`${API}/receipt/admin`, { sale },
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + getCookie('token')
            }
        })
}
export const addPreSale = (sale) => {

    return axios.post(`${API}/presale`, { sale },
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + getCookie('token')
            }
        })
}

export const updateStatus = (id, status) => {

    return axios.patch(`${API}/receipt/${id}/changestatus`, status,
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + getCookie('token')
            }
        })
}

export const updatePreStatus = (id, status) => {

    return axios.patch(`${API}/presale/${id}/changestatus`, status,
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + getCookie('token')
            }
        })
}

export const cancelOneSale = (id, motivo) => {

    return axios.patch(`${API}/receipt/${id}/cancel`, { reason: motivo },
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + getCookie('token')
            }
        })
}

export const cancelPreSale = (id, motivo) => {

    return axios.patch(`${API}/presale/${id}/cancel`, { reason: motivo },
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + getCookie('token')
            }
        })
}

export const getAllPre = (limit = 100, search = "") => {
    return fetch(`${API}/presale?search=${search}&limit=${limit}`, {
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + getCookie('token')
        }
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
}

export const getAll = (limit = 100, search = "", skip = 1, sortModel = null, filters = { users: [] }) => {

    return fetch(generateUrl(limit, search, skip, sortModel, filters), {
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + getCookie('token')
        }
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
}

let generateUrl = (limit = 100, search = "", skip = 1, sortModel = null, filters = { users: [], status: [], crm: 'all' }, urlAdd = "") => {
    let sort = ""
    let status = ""
    let crm = ""
    if (sortModel && sortModel.length > 0) {
        sort = `&sort=${sortModel[0].sort == 'asc' ? '+' : '-'}${sortModel[0].field}`
    }
    if (filters.crm == 'yes') {
        crm = "&crm=yes"
    } else if (filters.crm == 'no') {
        crm = "&crm=no"
    }

    return `${API}/receipt${urlAdd}?search=${search}&limit=${limit}&skip=${skip}${sort}${crm}${filters.users && filters.users.map((u) => `&user=${u}`).join('')}${filters.status && filters.status.map((u) => `&status=${encodeURIComponent(u)}`).join('')}`
}

export const downloadSales = (limit = 100, search = "", skip = 1, sortModel = null, filters = { users: [] }) => {

    return axios({
        url: generateUrl(limit, search, skip, sortModel, filters, "/excel"),
        method: 'GET',
        responseType: 'blob', // important
        headers: {
            'Authorization': 'JWT ' + getCookie('token')
        }
    })
}

export const get = (id) => {
    return fetch(`${API}/receipt/${id}`, {
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + getCookie('token')
        }
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
}
export const phoneReload = (id) => {
    return fetch(`${API}/receipt/${id}/phonesearch`, {
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + getCookie('token')
        }
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
}


export const getVentas = (page) => {
    return fetch(`${API}/sales?page=${page}`, {
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + getCookie('token')
        }
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
}

export const getVentasGrupo = (page) => {
    return fetch(`${API}/sales/getGroup?page=${page || 1}`, {
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + getCookie('token')
        }
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
}

export const getContactsByPhone = (phone) => {
    return fetcher({
        url: `/comercial/sale/getContactByPhone?phone=${phone}`,
        throwError: false,
        defaultContentType: true,
    })
}