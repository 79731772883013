import { getCompany, isAuth } from '@/actions/auth'
import { ContactService } from '@/actions/crm/contact-controller'
import useDebounce from '@/components/core/functions/useDebouce'
import { CustomFormField } from '@/components/ui/custom-form-field'
import { FormField, FormItem } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import validatePhoneNumber from '@/lib/validatePhoneNumber'
import { E164Number } from 'libphonenumber-js'
import _ from 'lodash'
import React from 'react'
import useGetIsBlockNumber from '../Prospect/libs/hooks/useGetBlockNumber'
import { TStates } from './ContactPhoneInput'
import PhoneItem from './phone-item'

export default function PhoneList({
    contextForm,
    defaultPhones,
    isModifyMode,
    contact,
    handleSetMessageError,
    handleRemoveArray,
    title,
    placeholder,
    handleAddArray,
    onCLoseSheet,
    channels = [],
    enableInternalPhoneNumbers,
    defaultPhoneMetadata = []
}: any) {
    const [lastPhoneError, setLastPhoneError] = React.useState(new Map())
    const [inxPhoneValidateDebounce, setInxPhone] = React.useState<number>(0)
    const [isLoadingFetchValidatePhone, setIsLoadingValidatePhone] = React.useState<boolean>(false)

    const { handlePressBlockNumber } = useGetIsBlockNumber();
    const phonesMetadata = contextForm.watch('phonesMetadata') || [];

    const isRequiredPhoneNumber = React.useMemo(() => !_.get(getCompany(), "crmConfig.disableObligatoryPhone", false) && channels.length == 0, [getCompany(), channels]);

    const user = React.useMemo(() => isAuth(), [])
    const phoneCode = _.get(getCompany(), "country", "uy")

    const phones = contextForm.watch("phones") || []
    const phoneToValidate = useDebounce(phones[inxPhoneValidateDebounce] || "");
    const extensionToValidate = useDebounce(phonesMetadata?.[inxPhoneValidateDebounce]?.extensionNumber || "");
    const usePhones = (phones.length == 0 ? [""] : phones);

    const validatePhone = () => {
        let phoneValidate: any = phones[inxPhoneValidateDebounce];

        // Validate phone if is valid
        let firstValidator = validatePhoneNumber(phoneValidate.trim(), phoneCode);
        const metadata = phonesMetadata?.[inxPhoneValidateDebounce] || { extensionNumber: '' };
        const defaultMetadata = defaultPhoneMetadata?.[inxPhoneValidateDebounce] || { extensionNumber: '' };

        //Validates if the number does not exist in the saved numbers, if it almost does not exist, the request is made
        if (!firstValidator?.valid || (isModifyMode &&
            defaultPhones.includes(phoneValidate) && (!enableInternalPhoneNumbers || (enableInternalPhoneNumbers && metadata.extensionNumber == defaultMetadata.extensionNumber)))) {
            setIsLoadingValidatePhone(false)
            return;
        }

        phoneValidate = firstValidator.number

        const phoneErrorKey = !enableInternalPhoneNumbers
            ? phoneValidate.toString()
            : `${phoneValidate}-${metadata.extensionNumber}`;

        if (lastPhoneError.get(phoneErrorKey)) {
            setIsLoadingValidatePhone(false)
            return handleErrorPhoneNumber()
        };

        setIsLoadingValidatePhone(true);

        ContactService.validateNumbers(phoneValidate, contact._id, metadata?.extensionNumber || '')
            .then((res) => {
                if (!res.success && res?.error) {
                    const { type, blockReason, phoneNumber } = res || {};

                    const newError = lastPhoneError.set(phoneErrorKey, res);

                    if (type && type === 'blocknumber') {
                        handlePressBlockNumber({
                            externalBlockReason: blockReason,
                            externalPhoneNumber: phoneNumber
                        })
                    }

                    setLastPhoneError(newError)
                    handleErrorPhoneNumber()
                }
            })
            .catch(() =>
                handleSetMessageError(
                    `Ocurrió un error al validar el número ${phoneValidate}`
                )
            )
            .finally(() => setIsLoadingValidatePhone(false))
    }

    React.useEffect(() => {
        if (phoneToValidate) validatePhone()
    }, [phoneToValidate, extensionToValidate])

    const handleErrorPhoneNumber = () => {
        let allPhones = [...phones]
        const allLastErrors = Array.from(lastPhoneError).map(([_, value]) => value).map(e => e.error)

        allPhones.forEach((_, inx) => {
            if (
                allLastErrors.includes(
                    contextForm.getFieldState(`phones.${inx}`).error?.message
                )
            ) {
                contextForm.clearErrors(`phones.${inx}`)
            }
        })

        const validPhoneNumbers = allPhones.map((phone) => {
            if (!phone) return ""
            let firstValidator = validatePhoneNumber(phone, phoneCode);

            return !firstValidator?.valid || !/^\d+$/.test(phone)
                ? phone.trim()
                : firstValidator.number
        })

        const uniquePhones = Array.from(new Set(validPhoneNumbers));

        uniquePhones.forEach((number: E164Number, inx: number) => {
            const validate = validatePhoneNumber(number, phoneCode);

            const metadata = phonesMetadata?.[inx] || { extensionNumber: '' };
            const phone = `${validate?.number ?? number.trim()}`;
            const errorKey = !enableInternalPhoneNumbers ? phone : `${phone}-${metadata.extensionNumber}`;

            const withError = lastPhoneError.get(errorKey)?.error

            if (withError) {
                const lastIndex = validPhoneNumbers.lastIndexOf(number.trim())
                if (lastIndex !== -1) {
                    contextForm.setError(`phones.${lastIndex}`, {
                        message: withError,
                    })
                }
            }
        })
    }

    React.useEffect(() => {
        let phoneValidate: any = phoneToValidate
        let firstValidator = validatePhoneNumber(phoneValidate.trim(), phoneCode)

        if (
            !firstValidator?.valid &&
            (!defaultPhones?.includes(phoneToValidate) || phonesMetadata?.[inxPhoneValidateDebounce].extensionNumber !== extensionToValidate) &&
            phones[inxPhoneValidateDebounce] !== ""
        ) {
            setIsLoadingValidatePhone(true)

            const timeout = setTimeout(() => {
                setIsLoadingValidatePhone(false)
                handleValidatePhoneNumber(
                    inxPhoneValidateDebounce,
                    phones[inxPhoneValidateDebounce]
                )
            }, 300)

            return () => clearTimeout(timeout)
        }
    }, [phoneToValidate])

    React.useEffect(() => {
        handleErrorPhoneNumber()
    }, [lastPhoneError, phones, phoneToValidate])

    React.useEffect(() => {
        phones.forEach((phone, inx) => {
            handleValidatePhoneNumber(inx, phone)
        })
    }, [phones, phoneToValidate])

    const handleValidatePhoneNumber = (inx, value) => {
        if (!value) return contextForm.clearErrors(`phones.${inx}`)
        const firstValidator = validatePhoneNumber(value, phoneCode)

        if (!firstValidator?.valid && value.trim() !== "") {
            contextForm.setError(`phones.${inx}`, {
                message: `El número ingresado no es válido`,
            })
        } else {
            contextForm.clearErrors(`phones.${inx}`)
            handleErrorPhoneNumber()
        }
    }

    const getPhoneState = ({ value, inx }): TStates | null => {
        const phoneValidate = validatePhoneNumber(value ?? "", phoneCode)

        if (isLoadingFetchValidatePhone && inx == inxPhoneValidateDebounce) return 'loading';

        const metadata = phonesMetadata?.[inx] || { extensionNumber: '' };
        const phone = `${phoneValidate?.number}`;
        const errorKey = !enableInternalPhoneNumbers ? phone : `${phone}-${metadata.extensionNumber}`;

        if (!!lastPhoneError.get(errorKey) || !!contextForm.getFieldState(`phones.${inx}`).error) return 'error'
        if (value?.trim() !== "" && phoneValidate?.valid && (!isLoadingFetchValidatePhone || inx !== inxPhoneValidateDebounce)) return 'success'

        return null
    }

    const onRemovePhoneNumber = (inx: number) => {
        handleRemoveArray('phones', inx)();
        handleRemoveArray('phonesMetadata', inx)();
    };

    const onAddPhone = () => {
        handleAddArray("phones")();
        let allPhonesMetadata = [...phonesMetadata];
        allPhonesMetadata.push({ extensionNumber: '' });
        contextForm.setValue('phonesMetadata', allPhonesMetadata);
    };

    return <div className="grid gap-2">
        <Label>
            {title || 'Teléfonos'}{isRequiredPhoneNumber && <span className="text-primary"> *</span>}
        </Label>
        <ul className="grid gap-3">
            {
                usePhones.map((__, inx: number) => {
                    return (
                        <div key={`phone-${inx}`} className={'flex flex-col gap-2'}>
                            <FormField
                                control={contextForm.control}
                                name={`phones.${inx}`}
                                rules={{ required: isRequiredPhoneNumber }}
                                render={({ field }) => (
                                    <FormItem>
                                        <PhoneItem
                                            contextForm={contextForm}
                                            field={field}
                                            user={user}
                                            onCLoseSheet={onCLoseSheet}
                                            placeholder={placeholder}
                                            index={inx}
                                            countryCode={phoneCode}
                                            lastPhoneError={lastPhoneError}
                                            handleAddArray={onAddPhone}
                                            isRequired={isRequiredPhoneNumber}
                                            handleRemoveArray={() => onRemovePhoneNumber(inx)}
                                            handleSetPhoneIndex={() => setInxPhone(inx)}
                                            handleErrorPhoneNumber={handleErrorPhoneNumber}
                                            state={getPhoneState({ value: field.value, inx })}
                                            enableInternalPhoneNumbers={enableInternalPhoneNumbers}
                                            metadata={phonesMetadata[inx]}
                                        />
                                    </FormItem>
                                )}
                            />
                            {
                                enableInternalPhoneNumbers && (
                                    <CustomFormField
                                        name={`phonesMetadata.${inx}.extensionNumber`}
                                        control={contextForm.control}
                                        fnElement={({ field }) => (
                                            <Input
                                                placeholder="Número interno"
                                                value={field.value || ''}
                                                onChange={(e) => {
                                                    const value = e.target.value.replace(/\D/g, '');
                                                    if (value) contextForm.clearErrors(`phones.${inx}`);
                                                    field.onChange(value);
                                                    handleErrorPhoneNumber();
                                                    setInxPhone(inx);
                                                }}
                                            />
                                        )}
                                    />
                                )
                            }
                        </div>
                    )
                })
            }
        </ul>
    </div>
}