import { SaleInteraction } from "@/interfaces/crm/contact"
import { ConversationInterface } from "@/interfaces/Interface"
import { cn } from "@/lib/utils"
import _ from "lodash"
import moment from "moment"
import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import { getCompany } from "../../../actions/auth"
import ArchiveBubble from "./ArchiveBubble"
import ChannelMessageBubble from "./ChanneMessageBubble"
import ContactTakenBubble from "./ContactTakenBubble"
import DisregardedBubble from "./DisregardedBubble"
import EmailBubble from "./EmailBubble"
import ExpiredMeeting from "./ExpiredMeeting"
import { getProfile } from "./libs/helpers/getProfile"
import MeetingBubble from "./MeetingBubble"
import MeetingReminderBubble from "./MeetingReminderBubble"
import { NoteBubble } from "./NoteBubble"
import PhoneBubble from "./PhoneBubble"
import ReminderBubble from "./ReminderBubble"
import SaleBubble from "./SaleBubble/index"
import SaleUpdateBubble from "./SaleUpdateBubble"
import ScheduledMessage from "./scheduled-message"
import SmsBubble from "./SmsBubble"
import SubConversationClosedBubble from "./SubConversationClosedBubble"
import SubConversationCreatedBubble from "./SubConversationCreatedBubble"
import TagModifiedBubble from "./TagModifiedBubble"
import TransferBubble from "./TransferBubble"
import UnOfficialWhatsappBubble from "./UnOfficialWhatsappBubble"

moment.locale("es")

const defaultStyle = {
  note: "",
  meeting: "",
  date: "",
  "unofficial-whatsapp": "",
  reminder: "",
  sms: "",
  phoneCall: "",
  channelMessage: "",
  email: "",
  saleupdate: "",
}

const Bubble = React.memo(
  ({
    interaction,
    notResponseCall,
    agent: currentAgent,
    customStyles = defaultStyle,
  }: ConversationInterface) => {
    const prospect = useSelector((state: any) => state.crm.contact)
    let company = getCompany()

    const profile = useMemo(() => getProfile({ currentAgent, interaction, prospect }), [interaction, prospect, currentAgent])

    switch (interaction.type) {
      case "date":
        return (
          <div
            className={cn(
              "w-full flex gap-5 my-[12px]  justify-center items-center text-[16px]",
              customStyles?.date
            )}
          >
            <div className="p-1 font-medium rounded-sm bg-white shadow-bubble-right px-5 text-[12px] text-[#6B7280]">
              {interaction._id}
            </div>
          </div>
        )
      case "note":
        return (
          <NoteBubble
            customStyle={customStyles?.note}
            interaction={interaction}
            channel={interaction.content.channel}
            profile={profile}
          />
        )
      case "unofficial-whatsapp":
        return (
          <UnOfficialWhatsappBubble
            date={new Date(interaction.createdAt)}
            profile={profile}
            interaction={interaction}
          />
        )
      case "phoneCall":
        return (
          <PhoneBubble
            customStyle={customStyles?.phoneCall}
            profile={profile}
            interaction={interaction}
            agent={interaction.agent}
            notResponseCall={notResponseCall}
          />
        )
      case "reminder":
        return <ReminderBubble
          profile={profile}
          interaction={interaction}
          customStyle={customStyles?.reminder}
        />
      case "transfer": return <TransferBubble interaction={interaction} />
      case "archiving": return <ArchiveBubble interaction={interaction} />
      case "visit":
        let visitText = interaction.content.state
        _.get(company, "visitResults", []).map((a) => {
          if (a.code == interaction.content.state) visitText = a.name
        })

        return (
          <NoteBubble
            customStyle={customStyles?.note}
            interaction={interaction}
            profile={profile}
            channel="visit"
            noteType="visit"
            subTitle={visitText}
            origin="agent"
          />
        )
      case "saleupdate":
        return (
          <SaleUpdateBubble
            customStyle={customStyles?.saleupdate}
            interaction={interaction}
            date={new Date(interaction.createdAt)}
          />
        )
      case "contactTaken":
        return (
          <ContactTakenBubble
            date={new Date(interaction.createdAt)}
            agent={interaction.agent}
          />
        )
      case "channelMessage":
        return (
          <ChannelMessageBubble
            message={interaction.content.message}
            customStyle={customStyles?.channelMessage}
            date={new Date(interaction.createdAt)}
            profile={profile}
            agent={interaction.agent}
            medium={interaction.content.medium}
            origin={interaction.content.origin}
            deleted={interaction.content.deleted}
            status={interaction.content.status}
          />
        )
      case "email":
        return (
          <EmailBubble
            profile={profile}
            customStyle={customStyles?.email}
            agent={interaction.agent}
            content={interaction.content}
            origin={interaction.content?.origin}
            status={interaction.status}
            createdAt={interaction.createdAt}
          />
        )
      case "tag":
        return (
          <TagModifiedBubble
            from={interaction.content?.from}
            to={interaction.content?.to}
            date={new Date(interaction.createdAt)}
            agent={interaction.agent}
          />
        )
      case "disregarded":
        return (
          <DisregardedBubble
            date={new Date(interaction.createdAt)}
            agent={interaction.agent}
          />
        )
      case "meeting":
        return <MeetingBubble
          interaction={interaction}
          contactId={interaction.contact}
        />
      case "meeting-reminder": return <MeetingReminderBubble interaction={interaction} />
      case "smsMessage":
        return <SmsBubble
          customStyle={customStyles?.sms}
          interaction={interaction}
          profile={profile}
        />
      case "subconversation-created":
        return <SubConversationCreatedBubble
          prospect={prospect}
          interaction={interaction}
        />
      case "subconversation-closed":
        return <SubConversationClosedBubble interaction={interaction} />
      case 'scheduled-message': return <ScheduledMessage
        profile={profile}
        interaction={interaction}
      />
      case "expired-meeting": return <ExpiredMeeting interaction={interaction} />
      case 'sale': return <SaleBubble interaction={interaction as SaleInteraction} />
      default:
        return (
          <div className="w-full flex justify-center items-center">
            Interaccion desconocida
          </div>
        )
    }
  }
)

export const stringToUppercase = (srt: string) => srt?.toUpperCase() || ''
export default Bubble
