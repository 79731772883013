"use client"

import * as React from "react"
import { format } from "date-fns"
import { Calendar as CalendarIcon } from "lucide-react"

import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import { Calendar } from "@/components/ui/calendar"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover"
import { es } from "date-fns/locale"


interface DatePickerProps {
    value: Date | undefined,
    onChangeValue: (value: Date | undefined) => void,
    classNameTriggerButton?: string,
    extraTriggerContent?: React.ReactNode 
}

export function DatePicker(props: DatePickerProps) {
    const { onChangeValue, value, classNameTriggerButton, extraTriggerContent } = props;

    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button
                    variant={"outline"}
                    className={cn(
                        "w-[280px] justify-start text-left font-normal",
                        !value && "text-muted-foreground"
                        , classNameTriggerButton)}
                >
                    <CalendarIcon className="mr-2 h-4 shrink-0 w-4" />
                    {value ? format(value, "PPP", { locale: es }) : <span>Seleccionar fecha</span>}
                    {extraTriggerContent}
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
                <Calendar
                    mode="single"
                    selected={value}
                    onSelect={onChangeValue}
                    initialFocus
                />
            </PopoverContent>
        </Popover>
    )
}
