import { isAuth } from "@/actions/auth";
import { useChannels } from "@/components/channels/helpers/hooks/useChannels";
import { PhoneCall } from "@/interfaces/crm/contact";
import { setOpenReminderCall } from "@/redux/slices/crm";
import { CardActions, Paper } from "@mui/material";
import { useMemo } from "react";
import ChatInput from "../ChatInput";
import TemplateSelect from "../ChatInput/TemplateSelect";
import AddMeetingModal from "../Modal/meeting/AddMeetingModal";
import useGetIsBlockNumber from "../Prospect/libs/hooks/useGetBlockNumber";
import RequestSale from "../requestsale/RequestSale";
import PhoneResponse, { callState } from "./PhoneResponse";
import ReminderFast from "./ReminderFast";

export function ChatInputHandler({ action, prospect, sendMessage, sendMultimedia, sendMultimediaPersonalWPP, id, downChatFc, dispatch, handelActionPress, activeChannel, setAction }) {
    const { handlePressBlockNumber, someBlockedSetting } = useGetIsBlockNumber()
    const { gracePeriodOpen } = useChannels(activeChannel, prospect, id)

    const chatBloqued = useMemo(() => someBlockedSetting('disableChat', prospect.phones[0]), [prospect])

    const getResponseCall = (interaction: PhoneCall) => {
        if (!interaction || interaction.type != "phoneCall" || (interaction.type === 'phoneCall' && interaction.localStatus && interaction.localStatus !== 'success')) return false
        return interaction.content.state == "calling"
    }

    const handleOnCall = (callResult: callState) => {
        if (callResult === "cancel") dispatch(setOpenReminderCall(prospect._id))
        else if (callResult === 'done') handelActionPress("note")
        downChatFc();
    }

    const handlePressChatBloqued = () => chatBloqued && handlePressBlockNumber(chatBloqued)

    const lastInteraction = useMemo(() => {
        return prospect.interactions.find(({ type }) => type !== 'contactTaken')
    }, [prospect.interactions])

    return (
        <div className="w-full pb-0 h-full">
            {
                getResponseCall(lastInteraction)
                    ? (
                        <PhoneResponse
                            id={prospect._id}
                            interactionid={lastInteraction._id}
                            phone={lastInteraction.content.phone}
                            onCall={handleOnCall}
                        />
                    )
                    : action == "form" ? (
                        <RequestSale
                            open={action == "form"}
                            contactid={id}
                            close={() => dispatch(setAction("select-more"))}
                        />
                    )
                        : [
                            "whatsapp",
                            "facebook",
                            "instagram",
                            "channel-chat",
                            "channel-sms"
                        ].includes(action) ? (
                            (gracePeriodOpen || action === "channel-sms") ? (
                                <ChatInput
                                    send={sendMessage}
                                    disableChat={!!chatBloqued}
                                    handlePressBlockNumber={handlePressChatBloqued}
                                    placeholder={"Escribe un mensaje..."}
                                    sendMultimedia={sendMultimedia}
                                    goBack={() => dispatch(setAction("note"))}
                                    channel={
                                        action == 'facebook' ? { name: "Facebook", type: 'fbk' }
                                            : action == 'instagram' ? { name: "Instagram", type: 'ig' }
                                                : activeChannel
                                    }
                                    options={{ enabledScheduledMessage: activeChannel?.type == 'unofficial_wpp' }}
                                    multimedia={
                                        ['unofficial_wpp', 'fb', 'ig', 'whatsapp_business', 'whatsapp_360_dialog'].includes(activeChannel?.type) ? {
                                            voice: true,
                                            image: true,
                                            file: true
                                        } : {}
                                    }
                                />
                            ) : (
                                <TemplateSelect
                                    type={!gracePeriodOpen ? 'fast' : undefined}
                                    send={sendMessage}
                                    goBack={() => gracePeriodOpen ? handelActionPress('note') : dispatch(setAction(action))}
                                    channel={
                                        action == 'facebook' ? { name: "Facebook", type: 'fbk' }
                                            : action == 'instagram' ? { name: "Instagram", type: 'ig' }
                                                : action == 'whatsapp' ? { name: "Whatsapp", type: 'wpp' }
                                                    : activeChannel
                                    }
                                />
                            )
                        )
                            : (action == "unofficial-whatsapp" || action === 'unofficial-whatsapp-from-shared-inbox')
                                ? (
                                    <ChatInput
                                        send={sendMessage}
                                        disableChat={!!chatBloqued}
                                        handlePressBlockNumber={handlePressChatBloqued}
                                        sendMultimedia={sendMultimediaPersonalWPP}
                                        placeholder="Escribe un mensaje..."
                                        goBack={() => dispatch(setAction("select"))}
                                        channel={{ name: 'Whatsapp personal', type: 'unofficial_wpp' }}
                                        options={{ enabledScheduledMessage: true }}
                                        multimedia={
                                            {
                                                voice: true,
                                                image: true,
                                                file: true
                                            }
                                        }
                                    />
                                )
                                : action == "whatsapp-note" ? (
                                    <ChatInput
                                        disableChat={!!chatBloqued}
                                        handlePressBlockNumber={handlePressChatBloqued}
                                        send={sendMessage}
                                        goBack={() => dispatch(setAction("select"))}
                                        placeholder={"Describe conversación por Whatsapp"}
                                    />
                                ) : action == 'update-whatsapp-message'
                                        ? <ChatInput
                                            send={sendMessage}
                                            disableChat={!!chatBloqued}
                                            placeholder="Escribe un mensaje..."
                                            goBack={() => dispatch(setAction("select"))}
                                            options={{ enabledScheduledMessage: false }}
                                        /> 
                                    : action == "reminder_long" ? (
                                        <Paper sx={{ borderRadius: "0px" }}>
                                            <CardActions disableSpacing sx={{ mt: "auto", borderRadius: "0px" }}>
                                                <ReminderFast
                                                    id={id}
                                                    type={"long"}
                                                    onClose={() => {
                                                        dispatch(setAction("note"));
                                                        downChatFc();
                                                    }}
                                                />
                                            </CardActions>
                                        </Paper>
                                    )
                                        : action == "meeting"
                                            ?
                                            <AddMeetingModal
                                                onOpenChange={() => dispatch(setAction("note"))}
                                                prospectId={prospect._id}
                                                open={true}
                                            />
                                            : (
                                                <ChatInput
                                                    send={sendMessage}
                                                    goBack={() => dispatch(setAction("select"))}
                                                    placeholder={"Escribe una nota..."}
                                                    colorSchemeConfig={{
                                                        background: "#FEF3C7",
                                                        borders: "#FAAC14",
                                                        bordersFocus: "#FAAC14",
                                                        submitButton: "#FAAC14",
                                                        backButton: "#FAAC14",
                                                        emojiButton: "GrayText",
                                                    }}
                                                    multimedia={{
                                                        image: isAuth()?.roles.includes('admin'),
                                                        file: isAuth()?.roles.includes('admin')
                                                    }}
                                                />
                                            )
            }
        </div>
    )
}