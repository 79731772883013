import RenderIconByName from "@/components/company/shortcuts/RenderIconByName";
import {
  Admin,
  Performance,
  Reports,
  Seller
} from "@/components/crm/Bubble/Icons/Sidebar";
import { CalendarDay, HandInMoney } from "@/components/crm/Bubble/Icons/index";
import { Avatar } from "@/components/ui/avatar";
import { getCompanyGroupConfig } from "@/helpers/companyGroups";
import GroupIcon from '@mui/icons-material/Group';
import InboxIcon from '@mui/icons-material/Inbox';
import {
  Building2,
  Goal,
  ListOrdered,
  Map,
  Megaphone,
  MessageSquare,
  MessagesSquare,
  Store,
  TextSelect
} from "lucide-react";
import "moment/locale/es";
import { FaPlug, FaReceipt } from "react-icons/fa";
import { IoIosPricetag } from "react-icons/io";

const getCompanyGroupIcon = () => {
  const { icon, title } = getCompanyGroupConfig()

  const defaultIcon = <Building2 className="w-[18px] h-[18px]" />

  switch (icon?.medium) {
    case 'lucide': {
      return <>
        {<RenderIconByName name={icon.path} className="w-[18px] h-[18px]" /> || defaultIcon}
      </>
    }
    case 'remoteUrl': {
      return (
        <Avatar className=" text-white font-medium flex items-center justify-center w-[18px] h-[18px] text-xl">
          <img
            src={icon.path}
            className="w-full h-full object-cover"
            alt={`Icon ${title.plural}`}
          />
        </Avatar>
      )
    }
    default: return defaultIcon
  }
}

export const LIST_OF_ROUTES = {
  HOME: {
    label: "Rendimiento",
    href: "/",
    icon: <Performance />
  },
  REPORTS: {
    label: "Reportes",
    href: "/reportes/prospect",
    icon: <Reports className="w-[15px] h-[15px]" />
  },
  // TRAINING: {
  //   label: "Entrenamiento",
  //   href: "/training",
  //   icon: <ClipboardCopy size={17} />,
  // },
  SELLERS: {
    label: "Vendedores",
    href: "javascript:void(0)",
    icon: <Seller />
  },
  SALES: {
    label: "Ventas",
    href: "/comercial/sale",
    icon: <IoIosPricetag style={{ fontSize: '17px' }} />,
  },
  SHARED_CONTACT_INBOX: {
    label: "Carteras compartidas",
    href: "/contact/sharedinbox",
    icon: <MessagesSquare size={17} />,
  },
  PRESALE: {
    label: "Crear preventa",
    href: "/presale",
    icon: <FaReceipt style={{ fontSize: '17px' }} />,
  },
  REQUESTS: {
    label: "Solicitudes",
    href: "/comercial/requestsale",
    icon: <HandInMoney className="w-[19px] h-[19px]" />
  },
  GOALS: {
    label: "Objetivos",
    href: "/comercial/goals",
    icon: <Goal className="w-[19px] h-[19px]" />
  },
  CAMPAIGNS: {
    label: "Campañas",
    href: "/campaigns",
    icon: <Megaphone size={18} />
  },
  INBOX: {
    label: "Inbox",
    href: "/contact/unassigned",
    icon: <InboxIcon style={{ fontSize: '18px' }} />,
  },
  CONVERSATIONS: {
    label: "Conversaciones",
    href: "/contact",
    icon: <MessageSquare size={18} />
  },
  CONTACTS: {
    label: "Contactos",
    href: "/contacts",
    icon: <GroupIcon style={{ fontSize: '20px' }} />
  },
  COMPANIES: {
    label: getCompanyGroupConfig().title.plural,
    href: "/companies",
    icon: getCompanyGroupIcon()
  },
  CALENDAR: {
    label: "Calendario",
    href: "/contact/calendar",
    icon: <CalendarDay className="w-[17px] h-[17px]" />
  },
  MAP: {
    label: "Mapa",
    href: "/contacts/map",
    icon: <Map className="w-[18px] h-[18px]" />
  },
  ACTIONS_HISTORY: {
    label: "Historial de acciones",
    href: "/actionsHistory",
    icon: <TextSelect className="w-[18px] h-[18px]" />
  },
  ADMINISTRATION: {
    label: "Administración",
    href: "/grupos",
    icon: <Admin />
  },
  NPS: {
    label: "NPS",
    href: "/comercial/netpromoterscore",
    icon: <ListOrdered size={19} />
  },
  COMPANY: {
    label: "Mi Empresa",
    href: "/company",
    icon: ({ group }) => {
      const { logo } = group || {}

      return logo
        ? <Avatar className=" text-white font-medium flex items-center justify-center w-[18px] h-[18px] text-xl">
          <img
            src={logo}
            className="w-full h-full object-cover"
            alt="User profile"
          />
        </Avatar>
        : <Building2 size={19} />
    }
  }
}



export enum SLUG_HEADER {
  SALE = 'comercial-sale',
  HOME = 'statistics',
  PRESALE = 'comercial-presale',
  RETENTION = 'retention',
  COMPANY = 'company',
  SETTINGS = 'settings',
  UNASSIGNED = 'unassigned',
  CONVERSATIONS = 'conversation',
  SHARED_CONTACT_INBOX = 'sharedContactInbox',
  CAMPAIGNS = 'campaigns',
  ACTIONS_HISTORY = 'actionsHistory',
  CONTACTS = 'contacts',
  CALENDAR = 'contact-calendar',
  MAP = 'contacts-map',
  GROUPS = 'groups',
  GOAL = 'comercial-goals',
  TRAINING = 'training',
  REPORTS = 'reports',
  REQUESTSALE = 'comercial-requestsale',
  COMPANIES = 'comercial-companies',
  NET_PROMOTER_SCORE = 'net-promoter-score'
}