import { splitTextIntoLines } from '@/helpers';
import React from 'react';
import Linkify from 'react-linkify';
import sanitizeHtml, { IOptions } from 'sanitize-html';

export const formatMessage = (message: string) => {
  const boldRegex = /\*(.*?)\*/g;
  const italicRegex = /_(.*?)_/g;
  const strikethroughRegex = /~(.*?)~/g;
  const monospaceRegex = /`(.*?)`/g;
  const underlineRegex = /__(.*?)__/g;

  let formattedMessage = message
    .replace(boldRegex, '<b>$1</b>')
    .replace(underlineRegex, '<u>$1</u>')
    .replace(italicRegex, '<i>$1</i>')
    .replace(strikethroughRegex, '<s>$1</s>')
    .replace(monospaceRegex, '<code>$1</code>')

  return formattedMessage;
};

export const sanitizeTextOptions: IOptions = {
  allowedTags: ['b', 'i', 'em', 'strong', 'u', 'a', 'code', 's'],
  allowedAttributes: {
    'a': ['href', 'target', 'rel'],
  },
  allowedSchemes: ['http', 'https', 'mailto'],
};

const StyledLink = ({ href, children }) => (
  <a href={href} className="text-blue-500 hover:text-blue-700 transition duration-150 ease-in-out" target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);

const LinkStyler = (decoratedHref, decoratedText, key) => {
  if (decoratedHref.startsWith('mailto:')) {
    const email = decoratedHref.replace('mailto:', '');
    return (
      <StyledLink href={decoratedHref} key={key}>
        {email}
      </StyledLink>
    );
  }

  return (
    <StyledLink href={decoratedHref} key={key}>
      {decoratedText}
    </StyledLink>
  );
};

const StyledLinkifyer = ({ children }: { children: string }) => {
  const processedMessage = formatMessage(children);
  const sanitizedMessage = sanitizeHtml(processedMessage, sanitizeTextOptions);

  return (
    <Linkify componentDecorator={LinkStyler}>
      <div dangerouslySetInnerHTML={{ __html: sanitizedMessage }} />
    </Linkify>
  )
}

export function StyledLinkifyerLines({ text }: { text: string }) {
  const splittedText: string[] = splitTextIntoLines(text);

  return <React.Fragment>
    {
      splittedText.map((line, inx) => (
        <>
          <StyledLinkifyer key={inx}>
            {line}
          </StyledLinkifyer>
          {inx !== splittedText.length - 1 && <br />}
        </>
      ))
    }
  </React.Fragment>
};

export default StyledLinkifyer;