import { isAuth } from "@/actions/auth";
import { formatFile } from "@/components/settings/templates/ModalAddTemplates";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { replaceObjectValues } from "@/helpers/replaceObjectValues";
import { setMatchTemplate, setUploadMultimediaType } from "@/redux/slices/crm";
import { ArrowLeft, Check, Files } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useChannels } from "../../../channels/helpers/hooks/useChannels";
import { defaultValueForEditor } from "../../uploadMultimedia/constants";
import { useChatInputContext } from "../Context";

interface ChatInputProps {
  send: (e: any, message: any, tag: any) => void;
  goBack: () => void;
  colorSchemeConfig?: {
    background: string;
    borders: string;
    bordersFocus: string;
    submitButton: string;
    backButton: string;
    emojiButton: string;
  };
  channel?: {
    name: string;
    type: string,
    fastTemplates?: any;
  },
  type?: 'fast' | 'userTemplate' | 'fastTemplatFromBar';
}

const defaultColorSchemeConfig = {
  background: "inherit",
  borders: "inherti",
  bordersFocus: "inherti",
  submitButton: "inherti",
  backButton: "inherti",
  emojiButton: "inherti",
};

const TemplateSelect = ({
  send,
  goBack,
  colorSchemeConfig,
  channel,
  type = undefined,
}: ChatInputProps) => {
  const action = useSelector((state: any) => state.crm.action);
  const activeChannel = useSelector((state: any) => state.crm.activeChannel);
  const prospect = useSelector((state: any) => state.crm.contact);
  const [searchText, setSearchText] = useState("");
  const searchTemplateTextFromInput = useSelector((state: any) => state.crm.searchTemplate);
  const companyTemplates = useSelector((state: any) => state.crm.companyTemplates);
  const dispatch = useDispatch()

  const { gracePeriodOpen, templates, selectedTemplate, setSelectedTemplate } = useChannels(activeChannel, prospect, prospect._id);

  const handleGoBack = (e) => {
    e.preventDefault();
    goBack();
    setSelectedTemplate(undefined);
  }

  const colorScheme = useMemo(
    () => (colorSchemeConfig ? colorSchemeConfig : defaultColorSchemeConfig),
    [colorSchemeConfig]
  );

  const activeTemplate = useMemo(() => {
    if (!gracePeriodOpen) return templates;

    const { fastTemplates = [] } = channel || {}
    const allTemplates = [...fastTemplates, ...templates]
    return allTemplates
  }, [templates, channel, gracePeriodOpen, type])

  const userTemplates = useMemo(() => {
    const user = isAuth()
    let templates: any[] = []
    if (user && user.templates) {
      const templatesFromUser = user.templates.filter((template) => template.channel === 'message')
      templates.push(...templatesFromUser)
    }

    if (companyTemplates.length > 0) {
      const templatesFromCompany = companyTemplates.filter((template) => template.type === 'message' && (template.channels.length > 0 ? template.channels.includes(channel?._id) : true))
      templates.push(...templatesFromCompany)
    }
    return templates
  }, [templates, channel, companyTemplates])

  const filterTemplates = (template) => {
    const templateText = template.name || template.title || template.message;
    const titleMatches = (template.name || template.title)?.toLowerCase().includes(searchText.toLowerCase());
    const messageMatches = template.message?.toLowerCase().includes(searchText.toLowerCase());
    return titleMatches || messageMatches;
  };

  const displayedTemplates = useMemo(() => {
    if (searchText.length > 0) {
      const allTemplates = [...templates, ...userTemplates];
      const filteredTemplates: any[] = allTemplates.filter(filterTemplates);
      return filteredTemplates
    } else {
      return []
    }
  }, [templates, userTemplates, searchText, searchTemplateTextFromInput])

  useEffect(() => {
    if (searchTemplateTextFromInput?.length > 0) {
      const cleanText = searchTemplateTextFromInput.substring(1);
      setSearchText(cleanText)
      dispatch(setMatchTemplate(displayedTemplates[0]))
    }
  }, [searchTemplateTextFromInput, displayedTemplates])

  const { setTextValue } = useChatInputContext()

  const replaceTemplateVariables = (message: string) => {
    const { name, lastname, phone_work, _id: userId, phone, email } = isAuth() || {}
    const { _id: companyId, displayName, country } = isAuth().group || {}

    const variables = {
      contact: prospect,
      group: {
        _id: companyId,
        displayName,
        country
      },
      user: {
        name,
        lastname,
        phone_work,
        _id: userId,
        phone,
        email
      }
    }
    return replaceObjectValues(message, variables)
  }

  const onSelectTemplate = (template, e) => {
    const { files = [], message = '', channel } = template || {}

    if (files.length > 0) {

      if (channel == 'message') {
        const filesFormated = files.map(file => ({
          ...defaultValueForEditor,
          ...formatFile(file, 'normal'),
          caption: message
        }));

        return dispatch(setUploadMultimediaType({ multimedia: filesFormated }))
      }
    }

    if (type === 'fast') return send(e, message, template)
    const messageWithVariables = replaceTemplateVariables(message)
    setTextValue(messageWithVariables)
    goBack()
  }

  return (
    <>
      <div className="w-full flex items-center space-x-2 justify-between">
        <div className={`flex items-center h-[35px] my-1 ${type !== 'userTemplate' ? 'px-4' : 'px-2'} gap-2`}>
          {
            (type == 'userTemplate') && (
              <Button type="button" size={'icon'} variant={'ghost'} style={{ color: colorScheme.backButton }} onClick={handleGoBack}>
                <ArrowLeft size={18} />
              </Button>
            )
          }
          <span className="gap-1 flex items-center">
            <Files size={16} />
            <p className="font-medium text-[15px]">Tus respuestas rápidas </p>
          </span>
        </div>
      </div>
      <div className={`w-auto flex flex-col ${type == 'fastTemplatFromBar' ? 'max-h-[190px] overflow-y-auto' : ''} mx-2 mb-2`}>
        {activeTemplate
          .filter(filterTemplates)
          .map((template, index) => (
            <>
              <TemplateItem
                selectedTemplateId={selectedTemplate?._id || ''}
                onSelectTemplate={onSelectTemplate}
                template={template}
              />
              {index !== activeTemplate.length - 1 && (
                <Separator className="w-full" orientation="horizontal" />
              )}
            </>
          ))}
        {(type == 'userTemplate' || type == 'fastTemplatFromBar') && userTemplates.filter(filterTemplates).map((template, index) => (
          <>
            {activeTemplate?.length > 0 && (
              <Separator className="w-full" orientation="horizontal" />
            )}
            <div
              key={template._id}
              onClick={(e) => onSelectTemplate(template, e)}
              className="cursor-pointer flex items-center p-2 hover:bg-slate-50 w-full justify-between"
            >
              <div className="flex flex-col">
                <p className="text-[#6B7280] font-medium">{template.name ?? template.title}</p>
                <p className="text-black/70 text-[14px] font-normal">{template.message}</p>
              </div>
            </div>
            {index !== userTemplates.length - 1 && (
              <Separator className="w-full" orientation="horizontal" />
            )}
          </>
        ))}
      </div>
    </>
  );
};

interface TemplateItemProps {
  onSelectTemplate: (template: any, e: any) => void,
  selectedTemplateId?: string,
  template: any,
}

export function TemplateItem({ template, onSelectTemplate, selectedTemplateId }: TemplateItemProps) {
  const { files = [], config, type } = template || {};
  const templateName = template?.name ?? template?.title;

  const GetTemplateContent = () => {
    let text = template?.message || ''

    if (Array.isArray(config?.components)) {
      const body = config.components.find((component) => (component?.type || '').toLowerCase() == 'body');
      if (body && body.text) text = body.text;
    }

    return (
      <p className="text-black/70 text-[14px] font-normal">
        {text}
      </p>
    )
  };

  return (
    <div
      className="cursor-pointer flex items-center p-2 hover:bg-slate-50"
      onClick={(e) => onSelectTemplate(template, e)}
    >
      <div className="flex flex-col">
        <p className="text-[#6B7280] font-medium">
          {templateName}
        </p>
        {GetTemplateContent()}
        {
          (files.length > 0) && (
            <p className="text-black/70 text-[14px] font-normal">
              {template.files.length}{template.files.length === 1 ? 'Archivo' : 'Archivos'}
            </p>
          )
        }
      </div>
      {template._id === selectedTemplateId && (<Check size={18} />)}
    </div>
  )
}

export default TemplateSelect;